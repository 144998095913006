

import React from 'react';
import { View, Text, Image} from '@react-pdf/renderer';
import styles from './PtPdfStyles';
import imageHeaderLeft from '../assets/pdf/img_header_left.png';
import imageHeaderRight from '../assets/pdf/img_header_right.png'; 

export const FirstPageHeader = () => (
  <View style={styles.sectionHeader} wrap={false} fixed>
    <Image src={imageHeaderLeft} style={styles.imageHeaderLeft}/>
    <Image src={imageHeaderRight} style={styles.imageHeaderRight}/>
  </View>
);

export const FirstPageTitle = () => (
  <View style={styles.sectionTitle} wrap={false}>
    <Text style={styles.textTitle}>
      Planungstool f&uuml;r Straßenbeleuchtungsanlagen
    </Text>
    <Text style={styles.textSubtitle}>
      Auswertung spezifischer Parameter gem&auml;&szlig; Normenreihe &Ouml;NORM EN 13201
    </Text>
  </View>
);